/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 import { useLocation } from "@reach/router"
 function Seo({ description, lang, meta, title, image: metaImage }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
           }
         }
       }
     `
   )
 
 
   const image = metaImage
   ? metaImage
   : null;
   const metaDescription = description || site.siteMetadata.description
   const defaultTitle = site.siteMetadata?.title
   const { pathname } = useLocation();
   var curentUrl = '';
   curentUrl = process.env.GATSBY_SITE_URL + pathname;
   if (!curentUrl.endsWith('/'))
     curentUrl = curentUrl + '/';
 
   //remove multiple slashes
   curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
   //set lowercase url
   curentUrl = curentUrl.toLowerCase();
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       link={[
         {
           href: curentUrl,
           rel: "canonical"
         }
       ]}
       title={title}
       titleTemplate={defaultTitle ? `%s` : null}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           property: `og:title`,
           content: title,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
             property: `og:url`,
             content: curentUrl,
         },
         {
             property: `og:site_name`,
             content: "ArunEstates",
         },
         {
           name: `twitter:card`,
           content: `summary`,
         },
         {
           name: `twitter:creator`,
           content: site.siteMetadata?.author || ``,
         },
         {
           name: `twitter:title`,
           content: title,
         },
         {
           name: `twitter:site`,
           content: "@arunestates",
         },
         {
           name: `twitter:url`,
           content: curentUrl,
         },
         {
           name: `twitter:description`,
           content: metaDescription,
         },
       ].concat(
         metaImage
             ? [
                 {
                     property: "og:image",
                     content: image,
                 },
                 {
                     property: "twitter:image",
                     content: image,
                 },
                 {
                     property: "og:image:width",
                     content: 717,
                 },
                 {
                     property: "og:image:height",
                     content: 428,
                 },
                 {
                     name: "twitter:card",
                     content: "summary_large_image",
                 },
             ]
             : [
                 {
                     name: "twitter:card",
                     content: "summary",
                 },
             ]
     ).concat(meta)}
     />
   )
 }
 
 Seo.defaultProps = {
   lang: `en-GB`,
   meta: [],
   description: ``,
 }
 
 Seo.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }
 
 export default Seo
 